import React from "react"

import WOW from 'wowjs';
import { Helmet } from "react-helmet"
import { findDOMNode } from "react-dom";
import * as yup from 'yup';

import UserContext from "../userContext.js"

import UserTopbar from "../components/userTopbar";

import icons from '../images/user-icons.svg'

import edit from '../images/edit.svg'

import pc from '../images/pc.svg'

import Footer from "../components/footer.jsx";

import SelectableList from "../components/selectableList/index.jsx";

import argentina from '../images/argentina.png'

import mt1 from '../images/my_troop_1.png'
import mt2 from '../images/my_troop_2.png'
import mt3 from '../images/my_troop_3.png'

import grid from '../images/user_grid.png'

import filter from '../images/filter_icon.png'

import ClosedSelectableList from "../components/ClosedselectableList/index.jsx";

import SwitchButton from "../components/switchButton/index.jsx";


import '../scss/user.scss'
import 'semantic-ui-css/semantic.min.css'

export default class user extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      player: false,
      game: 'CSGO',
      client: false,
    };
    this.references = {
      hero: React.createRef(),
      services: React.createRef(),
      numbers: React.createRef(),
      features: React.createRef(),
      logos: React.createRef(),
      contact: React.createRef(),
    }
  }

  componentDidMount() {
    this.setState({
      client: true,
    })
  }



  render() {
    return (
      <>
        {this.state.client &&
          <>
            <div className="user" >
              <UserContext.Consumer>
                {userObject => (
                  <>
                    <UserTopbar onClick={() => this.setState({ player: true })} onClose={() => this.setState({ player: false })} player={this.state.player} user={userObject} profilePic={userObject?.userObject?.picture || null} />
                    <Helmet>
                    </Helmet>
                    <div className="wrapper app">
                      <section className="games--section">
                        <div className="wrapper app">
                          <div className="linear--header">
                            <img className="icons" src={icons} />
                            <SwitchButton text={'Editar perfil'} onClick={() => this.setState({ step: 1 })} icon={edit} />
                          </div>
                          <div className="profile--section">
                            <div className="user--item user--box">
                              <img src={userObject?.userObject?.picture || null} />
                              <div className="baloon">
                                <p>
                                  1623 seguidores
                                </p>
                              </div>
                              <div className="info">
                                <p className="data">
                                  Juega 6 días a la semana.
                                </p>
                                <h3>
                                  {userObject?.userObject?.username || 'User'}
                                </h3>
                                <p className="data-2">
                                  Jugador profesional de CSGO. Juego Valorant cada tanto.
                                </p>
                              </div>
                            </div>
                            <div className="user--item troops--box">
                              <h3>
                                Mis Troops
                              </h3>
                              <div className="troops--list">
                                <div className="troop--item">
                                  <img src={mt1} />
                                  <p>isurus</p>
                                </div>
                                <div className="troop--item">
                                  <img src={mt2} />
                                  <p>KZD Team</p>
                                </div>
                                <div className="troop--item">
                                  <img src={mt3} />
                                  <p>TheCrew</p>
                                </div>
                              </div>
                            </div>
                            <div className="user--item games--box">
                              <h3>
                                Mis Juegos
                              </h3>
                              <div className="games--list">
                                <SelectableList connectIcon={true} selectablesList={['CSGO', 'Valorant', 'GTA V']} />
                              </div>
                            </div>
                            <div className="user--item country--box">
                              <img src={argentina} />
                              <h4>Argentina</h4>
                            </div>
                            <div className="user--item device--box">
                              <h3>
                                Dispositivos de juego
                              </h3>
                              <img src={pc} />
                            </div>
                            <div className="user--item statistics--box">
                              <div className="header">
                                <h3>
                                  Estadísticas
                                </h3>
                                <ClosedSelectableList selectablesList={['CSGO', 'Valorant', 'GTA V']} onClick={(text) => { this.setState({ game: text }) }} />
                              </div>
                              {this.state.game === "CSGO" &&
                                <div className="grid">
                                  <div className="row first">
                                    <div className="item">
                                      <p className="bold big">Posición</p>
                                      <p>1527 hs.</p>
                                    </div>
                                    <div className="item">
                                      <p className="bold">Tiempo jugado</p>
                                      <p>33156</p>
                                    </div>
                                    <div className="item">
                                      <p className="bold">Deaths</p>
                                      <p>56732</p>
                                    </div>
                                    <div className="item">
                                      <p className="bold">Daño total</p>
                                      <p>1543</p>
                                    </div>
                                    <div className="item">
                                      <p className="bold">Rango</p>
                                      <p>198</p>
                                    </div>
                                    <div className="item">
                                      <p className="bold">Visión</p>
                                      <p>945</p>
                                    </div>
                                    <div className="item">
                                      <p className="bold">Oro ganado</p>
                                      <p>1900</p>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="item">
                                      <p className="bold big">K/d ratio</p>
                                      <p>3,21</p>
                                    </div>
                                    <div className="item">
                                      <p className="bold">Asesinatos</p>
                                      <p>33156</p>
                                    </div>
                                    <div className="item">
                                      <p className="bold">Asistencias</p>
                                      <p>56732</p>
                                    </div>
                                    <div className="item">
                                      <p className="bold">P. jugadas</p>
                                      <p>1543</p>
                                    </div>
                                    <div className="item">
                                      <p className="bold">P. perdidas</p>
                                      <p>198</p>
                                    </div>
                                    <div className="item">
                                      <p className="bold">MVP</p>
                                      <p>945</p>
                                    </div>
                                    <div className="item">
                                      <p className="bold">Torres destruidas</p>
                                      <p>1900</p>
                                    </div>
                                  </div>
                                </div>
                              }
                              {this.state.game === "Valorant" &&
                                <div className="grid">
                                  <div className="row first">
                                    <div className="item">
                                      <p className="bold big">Posición</p>
                                      <p>2753 hs.</p>
                                    </div>
                                    <div className="item">
                                      <p className="bold">Tiempo jugado</p>
                                      <p>6156</p>
                                    </div>
                                    <div className="item">
                                      <p className="bold">Deaths</p>
                                      <p>7322</p>
                                    </div>
                                    <div className="item">
                                      <p className="bold">Daño total</p>
                                      <p>2313</p>
                                    </div>
                                    <div className="item">
                                      <p className="bold">Rango</p>
                                      <p>842</p>
                                    </div>
                                    <div className="item">
                                      <p className="bold">Visión</p>
                                      <p>521</p>
                                    </div>
                                    <div className="item">
                                      <p className="bold">Oro ganado</p>
                                      <p>3453</p>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="item">
                                      <p className="bold big">K/d ratio</p>
                                      <p>1,21</p>
                                    </div>
                                    <div className="item">
                                      <p className="bold">Asesinatos</p>
                                      <p>5136</p>
                                    </div>
                                    <div className="item">
                                      <p className="bold">Asistencias</p>
                                      <p>7332</p>
                                    </div>
                                    <div className="item">
                                      <p className="bold">P. jugadas</p>
                                      <p>5463</p>
                                    </div>
                                    <div className="item">
                                      <p className="bold">P. perdidas</p>
                                      <p>1238</p>
                                    </div>
                                    <div className="item">
                                      <p className="bold">MVP</p>
                                      <p>2435</p>
                                    </div>
                                    <div className="item">
                                      <p className="bold">Torres destruidas</p>
                                      <p>1350</p>
                                    </div>
                                  </div>
                                </div>
                              }
                              {this.state.game === "GTA V" &&
                                <div className="grid">
                                  <div className="row first">
                                    <div className="item">
                                      <p className="bold big">Posición</p>
                                      <p>2753 hs.</p>
                                    </div>
                                    <div className="item">
                                      <p className="bold">Tiempo jugado</p>
                                      <p>6156</p>
                                    </div>
                                    <div className="item">
                                      <p className="bold">Deaths</p>
                                      <p>7322</p>
                                    </div>
                                    <div className="item">
                                      <p className="bold">Daño total</p>
                                      <p>2313</p>
                                    </div>
                                    <div className="item">
                                      <p className="bold">Rango</p>
                                      <p>842</p>
                                    </div>
                                    <div className="item">
                                      <p className="bold">Misiones</p>
                                      <p>521</p>
                                    </div>
                                    <div className="item">
                                      <p className="bold">$ ganado</p>
                                      <p>3453</p>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="item">
                                      <p className="bold big">K/d ratio</p>
                                      <p>1,21</p>
                                    </div>
                                    <div className="item">
                                      <p className="bold">Asesinatos</p>
                                      <p>5136</p>
                                    </div>
                                    <div className="item">
                                      <p className="bold">Asistencias</p>
                                      <p>Sin Datos</p>
                                    </div>
                                    <div className="item">
                                      <p className="bold">P. jugadas</p>
                                      <p>5463</p>
                                    </div>
                                    <div className="item">
                                      <p className="bold">P. perdidas</p>
                                      <p>1238</p>
                                    </div>
                                    <div className="item">
                                      <p className="bold">MVP</p>
                                      <p>Sin Datos</p>
                                    </div>
                                    <div className="item">
                                      <p className="bold">Torres destruidas</p>
                                      <p>Sin Datos</p>
                                    </div>
                                  </div>
                                </div>
                              }
                            </div>
                          </div>
                          <section className="video--section">
                            <h4 className="video--title">Mis jugadas destacadas</h4>
                         
                              </section>
                          <img onClick={() => this.setState({player: true})} className="grid--img" src={grid} />
                        </div>
                      </section>


                    </div>
                  </>
                )}
              </UserContext.Consumer>
            </div>
            <Footer />
          </>
        }
        
      </>
    );
  }
}